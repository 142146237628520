import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        sum: 15,
    },
    reducers: {
        setItem: (state, action) => {
            const index = state.items.findIndex(x => x.product.id === action.payload.product.id);

            if (index > -1) {
                const items = [...state.items];
                items[index].count += 1;
                state.items = items;
            } else {
                state.items = [...state.items, { ...action.payload, count: 1 }];
            }

            state.sum = parseFloat(state.sum) + parseFloat(action.payload.product.product_price);
        },
        removeItem: (state, action) => {
            const items = [...state.items];
            state.sum = parseFloat(state.sum) - (parseFloat(items[action.payload].product.product_price) * items[action.payload].count);
            state.items = [...items.slice(0, action.payload), ...items.slice(action.payload + 1)];
        },
        plusCount: (state, action) => {
            const items = [...state.items];
            items[action.payload].count += 1;
            state.items = items;

            state.sum = parseFloat(state.sum) + parseFloat(items[action.payload].product.product_price);
        },
        minusCount: (state, action) => {
            const items = [...state.items];
            items[action.payload].count -= 1;
            state.items = items;

            state.sum = parseFloat(state.sum) - parseFloat(items[action.payload].product.product_price);
        },
        clearItems: (state) => {
            state.items = [];
            state.sum = 10;
        },
    }
});

export const { setItem, removeItem, plusCount, minusCount, clearItems } = cartSlice.actions;

export default cartSlice.reducer;
