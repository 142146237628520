import React from 'react';

import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';

import ModalComponent from '../../modal';
import {
    Block,
    Text,
    LinksBlock,
    Link,
} from './components';

const WaitAndSubscribeModal = ({ onClose }) => {
    return (
        <ModalComponent size="small" onClose={onClose}>
            <Block>
                <ClockIcon />
                <Text>Секундучку мы переходим к оплате</Text>
            </Block>
        </ModalComponent>
    )
};

export default WaitAndSubscribeModal;
