import React, { useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './App.css';

import Header from './components/header';
import Footer from './components/footer';

import CartPage from './pages/cart';
import HomePage from './pages/home';
import ScrollToTop from './components/scrollToTop';
// import CallButton from './components/callButton';

import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

function App() {
    const pizzasRef = useRef();
    const drinksRef = useRef();
    const howToOrderRef = useRef();
    const whyUsRef = useRef();

    // var param 
    const params = new URLSearchParams(window.location.search);
    const q = params.get("msisdn"); // is the number 123
    console.log(q);
    if(q==null) {
        
        console.log("EMPTY");

    } else {
        console.log(q)
        localStorage.setItem("msisdn", q);
    }

    const f = params.get("fullname"); // is the number 123
    console.log(f);
    if(f==null) {
        
        console.log("EMPTY");

    } else {
        console.log(f)
        localStorage.setItem("full_name", f);
    }

    console.log(window.location.search)

    return (
        <>
            <ScrollToTop />
            <div className="content">
                <Header 
                    pizzasRef={pizzasRef}
                    drinksRef={drinksRef}
                    howToOrderRef={howToOrderRef}
                    whyUsRef={whyUsRef}
                />
                
                <Switch>
                    <Route path="/" exact>
                        <HomePage 
                            pizzasRef={pizzasRef}
                            drinksRef={drinksRef}
                            howToOrderRef={howToOrderRef}
                            whyUsRef={whyUsRef}
                        />
                    </Route>

                    <Route path="/cart" exact>
                        <CartPage />
                    </Route>
                    
                    <Redirect to="/" />
                </Switch>
            </div>

            <div className="footer">
                <Footer 
                    pizzasRef={pizzasRef}
                    drinksRef={drinksRef}
                    howToOrderRef={howToOrderRef}
                    whyUsRef={whyUsRef}
                />
            </div>

        </>
    );
}

export default App;
