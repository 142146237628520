import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik'

import { addOrderDc } from '../../../api';

import ModalComponent from '../../modal';
import Loading from '../../dotsLoading';
import {
    Block,
    Title,
    PhoneNumberBlock,
    PhoneNumberLabel,
    PhoneBlock,
    PhoneCode,
    PhoneInput,
    ConfirmBlock,
    ConfirmButton,
    LoadingBlock,
} from './components';

import { PhoneContainer, PhoneError } from './components';

const OrderConfirmModal = ({ onClose, onConfirm }) => {
    const { items } = useSelector(state => state.cart);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        var msisdn = localStorage.getItem("msisdn");
        console.log(msisdn)
        if(msisdn!=null) {
            setFieldValue('phone', msisdn);
        }

        var fullname = localStorage.getItem("full_name");
        if(fullname!=null)
            setFieldValue('fullname', fullname);

    }, []);

    

    const handleFormSubmit = async (values) => {
        setLoading(true)


        const products = items.map((item) => { 
            return {
                product_id: item.product.id,
                product_count: item.count,
            } 
        });

        products.push({
            product_id: 9,
            product_count: 2,
        });
        
        const params = {
            phone_number: values.phone,
            address: '',
            full_name: values.full_name,
            products,
        };

        // console.log(params);
        var resp = await addOrderDc(JSON.stringify(params));
        console.log(resp);
        console.log(resp.order_id);
        var deeplink = 'http://pay.dc.tj/?a='+resp.order_id+'&s='+resp.order_sum+'&c=&f1=372&f2=&f3=';
        // console.log(deeplink);
        window.location.href=deeplink;
        //window.location.href='http://pay.dc.tj/?a=992928901106&s=10&c=Оплата_счета_'+resp.order_id+'&f1=124&f2=&f3='
        //onConfirm();
    };

    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            phone: '',
            full_name: ''
        },
        validationSchema: yup.object().shape({
            // phone: yup.string().required('Пожалуйста, введите номер телефона').length(9, 'Номер телефона должен состоять из 9 цифр')
        }),
        onSubmit: handleFormSubmit,
    });

    const handlePhoneInput = (value) => {
        const number = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setFieldValue('phone', number);
    };

    return (
        <ModalComponent size="small" onClose={onClose}>
            <Block>
                <Title>Подтверждение заказа</Title>

                <PhoneNumberBlock>
                    <PhoneNumberLabel>Номер телефона</PhoneNumberLabel>
                    
                    <PhoneContainer>
                        <PhoneBlock error={errors.phone}>
                            <PhoneCode>+992</PhoneCode>

                            <PhoneInput 
                                type="tel"
                                value={values.phone}
                                onChange={(event) => handlePhoneInput(event.target.value)}
                                maxLength="9"
                                autoFocus
                            />
                        </PhoneBlock>

                        <PhoneError>{errors.phone}</PhoneError>
                    </PhoneContainer>
                </PhoneNumberBlock>
                <PhoneNumberBlock>
                    <PhoneNumberLabel>Имя</PhoneNumberLabel>
                    
                    <PhoneContainer>
                        <PhoneBlock error={errors.fullname}>
                            
                            <PhoneInput 
                                type="text"
                                value={values.fullname}
                                
                            />
                        </PhoneBlock>

                        <PhoneError>{errors.fullname}</PhoneError>
                    </PhoneContainer>
                </PhoneNumberBlock>

                <ConfirmBlock>
                    <ConfirmButton onClick={() => handleSubmit()}>
                        Отправить
                    </ConfirmButton>
                </ConfirmBlock>
            </Block>
            
            {loading && (
                <LoadingBlock>
                    <Loading />
                </LoadingBlock>
            )}
        </ModalComponent>
    )
};

export default OrderConfirmModal;
